import React from "react"

import SEO from "../../../components/seo"
import Card from "../../../components/Card"
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb"

const TayamaProjectPage = props => {
  return (
    <>
      <SEO title="Projects" />
      <Breadcrumb
        crumbs={[["/"], ["/", "projects"], ["/", "projects", "/tayama"]]}
      />
      <div style={{ marginBottom: "300px" }} />
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Card>Tayama - website</Card>
        <Card>Tayama - rysunki techniczne</Card>
        <Card>Tayama - zdjęcia studyjne</Card>
      </div>
    </>
  )
}

export default TayamaProjectPage
